/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from "react";
import useScreenSize from "../../customHooks/useScreenSize";
import HeroReusableV1 from "./HeroReusableV1";
import Grid from "@mui/material/Grid";
import dynamic from "next/dynamic";

const HeroAnimation = dynamic(() =>
  import("components/marketing/HeroAnimation.js")
);

const HeroV1 = ({ loggedIn }) => {
  const screenSize = useScreenSize();
  const [paused, setPaused] = React.useState(true);
  // The Number extension for classNames based on the component
  const versionNumber = "100";

  const getMarginLeftComponent = () => {
    return screenSize === "smallScreen" ? ".4rem auto auto" : "auto";
  };

  const getMarginRightComponent = () => {
    return screenSize === "smallScreen" ? "4rem auto auto" : "auto";
  };

  return (
    <div className="layout-container">
      <Grid
        onMouseOver={() => setPaused(false)}
        onMouseOut={() => setPaused(true)}
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid item lg={5} md={12} sm={12} margin={getMarginLeftComponent()}>
          <HeroReusableV1
            loggedIn={loggedIn}
            versionNumber={versionNumber}
            headerWithDesc={true}
          />
        </Grid>
        <Grid item lg={7} md={12} sm={12} margin={getMarginRightComponent()}>
          <Grid justifyContent="center" alignItems="center" container>
            <HeroAnimation
              paused={screenSize === "smallScreen" ? false : paused}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default HeroV1;
